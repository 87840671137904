<div class="title-breadcrumbs">
  <div class="title-breadcrumbs-inner">
    <div class="container-fluid">
      <nav class="woocommerce-breadcrumb">
        <a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate }}</a>
        <span class="separator"> / </span>
        {{ 'checkout_breadcrumb' | translate }}
      </nav>
    </div>
  </div>
</div>


<div class="c-content-box c-size-sm c-overflow-hide c-bg-white pt-50">
  <div class="container-fluid c-container-50 ">

    <div class="row flex-md-row">

      <div class="col-lg-5 col-md-12 leftDivider  ">
        <ng-container *ngIf="this.loadUser==false">

          <div class="border-grey-register">
            <p>{{ 'already_have_account' | translate }} <span class="sign-link" (click)="openModalSignIn()">{{ 'here' | translate }}</span></p>
          </div>

          <!-- <div style="padding-bottom: 20px;" class="form-group border-grey-register">

            </div> -->
          </ng-container>

  <div style="padding-bottom: 20px;" class="form-group border-grey-register">
    <form  [formGroup]="checkoutForm"  >
      <ng-container *ngIf="this.cart && this.cart.length>0  ">
        <div class="">
          <span class="title_h3 c-font-16 helveticaltsd c-font-bold font-theme-color mb-10" *ngIf="this.loadUser==false">{{ 'no_account_yet_fill_form' | translate }}</span>
          <span class="title_h3 c-font-16 helveticaltsd c-font-bold font-theme-color mb-10" *ngIf="this.loadUser==true">{{ 'loginleft' | translate }}</span>
          <div class="mb-2"></div>
          <div class="row">
            <ng-container *ngIf="this.companyInfo && this.companyInfo.typewebsite==1  ">
              <div class="form-group col-md-12">
                <label class="control-label" for="firstname">{{ 'companynamereg' | translate }}<i class="font-theme-color pl-02 c-font-12  fa fa-asterisk hidden"></i></label>
                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" formControlName="firstname"  maxlength="32" id="firstname" value="" class="form-control c-square valRequired" />
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                   <div *ngIf="f.firstname.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
              </div>
              <div class="form-group col-md-12">
                <label class="control-label" for="obs">{{ 'personchargresp' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" formControlName="lastname" id="obs" maxlength="32" value="" class="form-control c-square valRequired" />
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                   <div *ngIf="f.lastname.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
              </div>
            </ng-container>
            <ng-container *ngIf="this.companyInfo && this.companyInfo.typewebsite==0 ">
              <div class="form-group col-md-6">
                <label class="control-label" for="firstname">{{ 'firstname' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" formControlName="firstname" id="firstname" value="" class="form-control c-square valRequired" />
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                   <div *ngIf="f.firstname.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
              </div>
              <div class="form-group col-md-6">
                <label class="control-label" for="lastname">{{ 'lastname' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" formControlName="lastname" id="lastname" value="" class="form-control c-square valRequired" />
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                   <div *ngIf="f.lastname.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
              </div>
            </ng-container>
          </div>

          <div class="row">
            <div class="form-group col-xs-12 col-sm-12 col-md-12">
              <label class="control-label" for="email">{{ 'email' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk required_field"></i></label>
              <input (change)="changeEmail()" type="email" [ngClass]="{ 'is-invalid': (this.submitted || this.voucherError ) && f.email.errors }" formControlName="email" id="email"   class="form-control c-square valRequired valEmail" />
              <div *ngIf="(this.submitted || this.voucherError ) && f.email.errors" class="invalid-feedback">
                 <div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
                 <div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
             </div>
            </div></div>

            <div class="row">
              <div class="form-group col-md-6">
                <label class="control-label" for="nif"><span class="niftext">{{ 'nif' | translate }}</span> </label>
                <input type="text" [attr.disabled]="(this.userInfo && this.userInfo.nif && this.userInfo.nif!='') ? 'disabled' : null" [ngClass]="{ 'is-invalid': submitted && f.nif.errors }" formControlName="nif" id="nif" value="" class="form-control c-square nifControl"  />
                <div *ngIf="submitted && f.nif.errors" class="invalid-feedback">
                   <div *ngIf="f.nif.errors.NifValidatorPT">{{ 'msg_valNif' | translate }}</div>
               </div>
              </div>

              <div class="form-group col-md-6">
                <label class="control-label" for="telephone">{{ 'telephone' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
                <div class="full-width mobile-side-byside">
                  <div class="col-md-4 pad0  pr-0 col-md-4 float-left inital_code_base_div">
                    <ng-container *ngIf="this.telephone_callingcode  ">

                      <ng-select2 [options]="this.optionsCalling" [data]="this.telephone_callingcode" [value]="351" class="inital_code_base selectpicker" [ngClass]="{ 'is-invalid': submitted && f.telephone_callingcode.errors }" formControlName="telephone_callingcode"  id="initialcode_phone">

                      </ng-select2 >


                    </ng-container>
                  </div>
                  <div class="col-md-8 pl-min-sm-0 pad0 float-left width-phone-xs">

                    <input type="text" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }" formControlName="telephone" maxlength="20" id="telephone"   class="form-control c-square valRequired valNumber" />
                  </div></div>

                  <div *ngIf="submitted && f.telephone.errors" class="invalid-feedback">
                     <div *ngIf="f.telephone.errors.required">{{ 'msg_valRequired' | translate }}</div>
                 </div>

                </div>
              </div>
              <div class="form-group">
                <label class="control-label" for="address">{{ 'address' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address" #invoiceaddress id="address"   class="form-control valRequired c-square" />
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                   <div *ngIf="f.address.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
              </div>
              <div class="form-group">
                <label class="control-label" for="address2">{{ 'addresscomplement' | translate }}  </label>
                <input type="text" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" formControlName="address2" maxlength="32" id="address2"   class="form-control c-square"/>
                <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                   <div *ngIf="f.address2.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
              </div>
              <div class="col-12 pad0 ">
                <div class="form-group col-md-5 pad0">
                  <label class="control-label" for="postalcode">{{ 'postalcod' | translate }}  <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
                  <input (change)="getpostalcode('idcountry', 'postalcode', 'locality')" type="text" [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" formControlName="postalcode" #invoicepostalcode name="postalcode" id="postalcode"     class="form-control valRequired c-square" />
                  <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                     <div *ngIf="f.postalcode.errors.required">{{ 'msg_valRequired' | translate }}</div>
                 </div>
                </div>

                <div class="form-group col-md-7  pr-0 pl-15 col-xs-12 col-sm-12">
                  <label class="control-label" for="locality">{{ 'locality' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
                  <input type="text" [ngClass]="{ 'is-invalid': submitted && f.locality.errors }" formControlName="locality" #invoicelocality   id="locality"   class="form-control valRequired c-square" />
                  <div *ngIf="submitted && f.locality.errors" class="invalid-feedback">
                     <div *ngIf="f.locality.errors.required">{{ 'msg_valRequired' | translate }}</div>
                 </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label full-width float-left" for="country">{{ 'country' | translate }}  <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
                <select #invoicecountry [ngClass]="{ 'is-invalid': submitted && f.idcountry.errors }" formControlName="idcountry" (change)="changecountry()"  *ngIf=" this.countries " id="country" class="form-control full-width float-left valRequired c-square countryControl" >
                  <option value="" >{{ 'selectcountry' | translate }}</option>

                  <ng-container *ngFor="let country of this.countries | keyvalue;  ">
                    <option value="{{ country.key }}" data-attr="{{ country.value.abbreviation }}" >{{ country.value.description[this.translate.currentLang] }}</option>
                  </ng-container >
                </select>

                <div *ngIf="submitted && f.idcountry.errors" class="invalid-feedback">
                   <div *ngIf="f.idcountry.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
              </div>
              <div class="shippingWrapper2 col-12 float-left pad0">

                <div class="shippingChangeBtn">
                  <div class="form-group col-xs-12 c-margin-b-5">
                    <div class="c-checkbox">
                      <input id="checkboxReplicate" (change)="addressShippingchange()" name="replicateInfo" class="c-check" type="checkbox">
                      <label for="checkboxReplicate" class="c-font-14">
                        <span class="inc"></span>
                        <span class="check"></span>
                        <span class="box"></span> {{ 'usesameaddress' | translate }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="shippingaddressWrapper2" [hidden]="this.checkboxReplicate==0">
                    <span class="title_h3 c-font-16 helveticaltsd c-font-bold ccblack pb-10 full-width float-left">{{ 'addressinfo' | translate }}</span>
                    <div class="">
                      <div class="form-group col-xs-12 c-margin-b-5">
                        <p class="c-font-12">{{ 'usesameaddresstext' | translate }} <br/></p>
                      </div>
                    </div>



                    <div class="form-group">
                      <label class="control-label" for="saddress">{{ 'ship_to' | translate }} </label>
                      <input type="text"   formControlName="shipping_responsable" maxlength="32"     class="form-control c-square"/>
                    </div>
                    <div class="form-group">
                      <label class="control-label" for="saddress">{{ 'address' | translate }} </label>
                      <input type="text" formControlName="shipping_address" #shippingaddress name="shipping[address]" maxlength="32" id="saddress"   class="form-control c-square"/>
                    </div>
                    <div class="form-group">
                      <label class="control-label" for="saddress2">{{ 'addresscomplement' | translate }}  </label>
                      <input type="text"  formControlName="shipping_address2" maxlength="32" id="saddress2"   class="form-control c-square"/>
                    </div>
                    <div class="form-group form-group-nopad">
                      <div class=" col-md-5  pad0 form-group">
                        <label class="control-label" for="spostalcode">{{ 'postalcod' | translate }}   </label>
                        <input (change)="getpostalcode('shipping_idcountry', 'shipping_postalcode', 'shipping_locality')" #shippingpostalcode formControlName="shipping_postalcode" type="text"    id="spostalcode"   class="form-control c-square" />
                      </div>
                      <div class="  col-md-7 pad0 form-group ">
                        <label class="control-label" for="slocality">{{ 'locality' | translate }}   </label>
                        <input #shippinglocality type="text" formControlName="shipping_locality" id="slocality"   class="form-control c-square" />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="control-label full-width float-left" for="scountry">{{ 'country' | translate }}  </label>
                      <select #shippingcountry formControlName="shipping_idcountry" (change)="changecountry()"  id="scountry" class="form-control c-square full-width float-left" >
                        <option value="" >{{ 'selectcountry' | translate }}</option>

                        <ng-container *ngFor="let country of this.countries | keyvalue;  ">
                          <option data-attr="{{ country.value.abbreviation }}" value="{{ country.key }}"  >{{ country.value.description[this.translate.currentLang] }}</option>
                        </ng-container >
                      </select>
                    </div>

                  </div>
                </div>



                <ng-container *ngIf="!this.emailCheck">
                  <div class="" id="createUserweb" style="display:block;">
                    <div class="form-group col-xs-12 c-margin-b-5">
                      <div class="c-checkbox">
                        <input id="checkboxPassword" checked (change)="this.checkboxPassword=!this.checkboxPassword" name="registerusers" class="c-check" type="checkbox"    >
                        <label for="checkboxPassword" class="c-font-14">
                          <span class="inc"></span>
                          <span class="check"></span>
                          <span class="box"></span> {{ 'register_user' | translate }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" id="passwordRegister" [hidden]="!this.checkboxPassword" >
                      <div class="form-group ">
                        <div class="row">
                          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0 float-left noPadMob mb1rm">
                            <label class="control-label" for="password">{{ 'password' | translate }}  <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk required_field"></i></label>
                            <input type="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" id="password" formControlName="password" class="form-control c-square valRequired valIgnore" />
                            <div *ngIf="this.submitted && f.password.errors" class="invalid-feedback">
                               <div *ngIf="f.password.errors.minlength">{{ 'min6char' | translate }}</div>
                           </div>
                          </div>
                          <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pr-0 pl-20 float-left noPadMob">
                            <label class="control-label" for="passwordconf">{{ 'confirmpassword' | translate }}  <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk required_field"></i></label>
                            <input type="password" id="passwordconf" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" fieldid="password"   formControlName="confirmPassword" class="form-control c-square valRequired valMatch valIgnore" />
                            <div *ngIf="this.submitted && f.confirmPassword.errors" class="invalid-feedback">
                               <div *ngIf="f.confirmPassword.errors.mustMatch">{{ 'msg_valMatchP' | translate }}</div>
                           </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </ng-container>
                    <br/>

                  </div>
                </ng-container>
                </form>
  </div>

                </div>
                <div class="col-lg-7 col-md-12 rightDivider ">

                  <h6><b>{{ 'product_list' | translate }}</b></h6>
                  <div class="mb-4"></div>
                  <div class="c-content-bar-1 c-align-left c-bordered c-bg-white pt-0">
                    <ul class="c-order list-unstyled">
                      <li class="row c-margin-b-15">
                        <div class="d-none  d-sm-none d-md-none d-lg-block d-xl-block full-width">
                          <div class="col-md-4 col-xs-4 col-lg-6 float-left">
                            <h2 class="c-font-14">{{ 'product' | translate }} </h2>
                          </div>
                          <div class="col-md-2 col-lg-2 col-xs-4 float-left">

                            <h2 class="c-font-14">{{ 'unitprice' | translate }} <span *ngIf="this.companyInfo && this.companyInfo.typewebsite==1" class='c-font-13'>{{ 'novat' | translate }}</span>  </h2>
                          </div>
                          <div class="col-md-3 col-lg-2 col-xs-4 float-left">
                            <h2 class="c-font-14">{{ 'quantity_checkout' | translate }} </h2>
                          </div>
                          <div class="col-md-2 col-lg-1 col-xs-3 float-left">
                            <h2 class="c-font-14">{{ 'total' | translate }} <span *ngIf="this.companyInfo && this.companyInfo.typewebsite==1" class='c-font-13'>{{ 'novat' | translate }}</span></h2>
                          </div>
                        </div>
                        <div class="d-md-block d-lg-none d-xl-none d-sm-block full-width">
                          <div class="col-3 col-xs-3  text-left float-left"><h2 class="c-font-13 noMargin">{{ 'product' | translate }} </h2></div>
                          <div class="col-9 float-left">
                            <div class="col-6 col-xs-6 c-small-padding float-left">
                              <h2 class="c-font-13 noMargin">{{ 'quantity_checkout' | translate }} </h2>
                            </div>
                            <div class="col-4 col-xs-3 c-small-padding float-left">
                              <h2 class="c-font-13 noMargin">{{ 'total' | translate }} <span *ngIf="this.companyInfo && this.companyInfo.typewebsite==1" class='c-font-13'>{{ 'novat' | translate }}</span></h2>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="row c-border-bottom"></li>


                      <ng-container *ngIf="this.cart && this.cart.length>0"  >
                        <!-- c-border-bottom -->
                        <li class="row c-margin-b-15 c-margin-t-15 " *ngFor=" let ct of  this.cart; let i=index  " >
                          <div class="d-none  d-sm-none d-md-none d-lg-block d-xl-block full-width ">
                            <div class="col-2 col-md-2 col-lg-2 c-font-14 float-left">
                              <a [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description |  urlpipe | lowercase) + '-'+ ct.iditem, ct.idcolor, ct.idsize ] | localize)" href="javascript:void(0)">
                                <img  class="img_cart" style="width: 80px; height:auto;" [src]="cartImg(ct)">
                              </a>
                            </div>
                            <div class="col col-md-2 col-lg-4 c-font-14 prodLinePad col-xs-12 col-sm-12 float-left">
                              <a [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description |  urlpipe | lowercase) + '-'+  ct.iditem, ct.idcolor, ct.idsize ] | localize)" href="javascript:void(0)">{{ cartDesc(ct)  }}</a>
                              <div class="c-margin-b-5" style="height:0;">&nbsp;</div>
                            </div>
                            <div class="clearfix d-md-none d-lg-none d-xl-none d-sm-block d-md-block"></div>
                            <div class="col col-md-2 col-lg-2 col-sm-4 col-xs-4 c-font-14 prodLinePad float-left">
                              <ng-container *ngIf="ct.withPromotion && ct.withPromotion == 1;else nopromotion">
                                <span class="itemPrice- old" style="text-decoration: line-through;">{{ cartPriceTxt(ct,1) }}€</span> /
                                <span style="color: red;">{{ cartPriceTxt(ct,2) }}€</span>
                               <!--  <p class="itemPrice-" style="color: red;">{{ cartPriceTxt(ct,1 }}€</p> -->
                              </ng-container>
                              <ng-template #nopromotion>
                                <p class="itemPrice- ">{{ cartPriceTxt(ct,2) }}€</p>
                              </ng-template>
                            </div>
                            <div class="col col-md-3 col-lg-2 col-sm-4 col-xs-4 c-font-14 prodLinePadQuant float-left" >

                              <div class="cart-plus-minus quantWrapper">
                                <div class="dec qtybutton" (click)="descProd(ct)">-</div>
                                <input type="text" [class]="'cart-plus-minus-box quantInput1 stockUpdQuant c-item-' "  data_min="1"      name="quant" min="1"   value="{{ ct.quantity | number:'1.0' }}" readonly disabled>
                                <div class="inc qtybutton" (click)="incProd(ct)">+</div>
                              </div>

                            </div>
                            <div class="col col-md-2 col-lg-1 col-sm-3 col-xs-3 c-font-14 prodLinePad float-left">
                              <p class="itemTotal-  itemTotal- "> {{ cartPrice(ct,ct.quantity,2) }}€</p>

                            </div>
                            <div class="col-1 col-md-1 col-lg-1 col-xs-1 col-xs-1    ">
                              <p class=""><button type="button" class="close pull-right" aria-label="Close" (click)="removeItemCart(ct, i)"><span aria-hidden="true">×</span></button></p>
                            </div>
                          </div>
                          <div class="d-lg-none d-xl-none d-sm-block d-md-block  col-xs-12 col-sm-12   ">
                            <div class="col col-3 col-3 col-xs-3   float-left ">
                              <a class="c-theme-link c-font-11 noMargin" [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description |  urlpipe | lowercase) + '-'+  ct.iditem, ct.idcolor, ct.idsize  ] | localize)" href="javascript:void(0)">

                                <img  class="img_cart" style="width: 80px; height:auto;" [src]="cartImg(ct)"/>
                                <p><a class="c-theme-link c-font-11 noMargin d-none d-sm-block" [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description |  urlpipe | lowercase) + '-'+  ct.iditem, ct.idcolor, ct.idsize   ] | localize)" href="javascript:void(0)">{{ ct.langs[this.translate.currentLang].description }}</a></p>

                              </a>
                            </div>
                            <div class="col-9 col-xs-9 float-left  d-block d-md-none">
                              <div class="row">
                                <div class="col-12 col-xs-12 float-left">
                                  <span class="font-12 float-left full-width">{{ ct.langs[this.translate.currentLang].description }} <span class="font-10 float-left full-width text-left">{{ 'Ref: '+ct.code }}</span></span>
                                </div>
                              </div>
                              <div class="row pt-10">
                                <div class="col-6 col-xs-6   float-left">
                                  <div class="cart-plus-minus quantWrapper quantWrapperMob">
                                    <div class="dec qtybutton" (click)="descProd(ct)">-</div>
                                    <input type="text" [class]="'cart-plus-minus-box quantInput1 stockUpdQuant c-item-' "  data_min="1"      name="quant" min="1"   value="{{ ct.quantity | number:'1.0'  }}" readonly disabled>
                                    <div class="inc qtybutton" (click)="incProd(ct)">+</div>
                                  </div>
                                </div>
                                <div class="col-3 col-xs-3   float-left">
                                  <p class="itemTotal-  itemTotal-  c-font-11 noMargin">{{ cartPrice(ct,ct.quantity,2) }}€</p>

                                </div>
                                <div class="col-1 col-1    pull-right">
                                  <p class=""><button type="button" class="close c-font-20 pull-right" aria-label="Close" (click)="removeItemCart(ct, i)"><span aria-hidden="true">×</span></button></p>
                                </div>
                                <div class="clearfix"></div>
                              </div>


                            </div>

                          </div>
                        </li>
                      </ng-container>

                      <ng-container *ngIf="this.cart  && this.cart.length>0">
                        <li class="row c-border-top c-margin-b-15"></li>

                        <li class="row c-options c-margin-b-15">
                          <!-- <div class="col-lg-2 d-none d-lg-block">&nbsp;</div> -->
                          <div class="col-lg-6  col-md-12 pl-0">
                            <div class="input-group col-12 c-square">
                              <input #voucherCode id="voucherCode" class="form-control c-square c-theme valRequired valIgnore" placeholder="{{ 'discountcuppon' | translate }}" type="text">
                              <span class="input-group-btn">
                                <button class="btn c-btn-grey-3 c-bg-black  " style="height: 38px;border-radius:0px" type="button" (click)="addVoucher(voucherCode.value)">{{ 'apply' | translate }}</button>
                              </span>
                            </div>
                          </div>

                        </li>


                        <li class="row">
                          <div class="voucherWrapper col-12 pad0" [hidden]="!this.vouchersArr || this.vouchersArr.length==0">
                            <div class="voucherCart hidden"></div>
                            <div class="col-lg-4 d-none d-lg-block float-left">&nbsp;</div>
                            <div class="col-lg-8 col-xs-6 col-sm-6 col-md-8">
                              <p class="c-font-14 c-font-bold">{{ 'cuppons' | translate }}</p>
                            </div>

                            <div class="clearfix"></div>


                            <div class="voucherContainner">

							<ng-container *ngIf="this.vouchersArr">
							  <ng-container *ngFor="let voucher of this.vouchersArr; let i=index;">
                  <div *ngIf="this.vouchersArr[i].accumulate==0" id="voucher_discount_msg"><font size="1">{{ 'Voucher_Discount_Promotion' | translate }} </font></div>
														 <div class="vouchWrap float-left pad0 col-12">
											 <div class="col-lg-4 d-none d-lg-block float-left">&nbsp;</div>
												 <div class="col-lg-8 col-xs-6 col-sm-6 col-md-8 noPadding  float-left"><div class="col-lg-6 col-xs-6 col-sm-6 col-md-6 float-left c-font-14 noPadding"><p class="">{{ voucher.code }}</p></div>
											 <div class="col-md-6 col-xs-6 col-sm-6 c-font-14 float-left noPadding"><p class="">{{ voucher.total }} {{ voucher.symbol }}
											 <button type="button" class="close pull-right" aria-label="Close" (click)="removeVoucher(i, voucher.idvoucher )"><span aria-hidden="true">&times;</span></button>
												 </p> </div></div>
										 </div><div class="col-lg-4 hidden-md hidden-sm hidden-xs">&nbsp;</div>
							</ng-container >
							</ng-container >

                            </div>
                          </div>
                        </li>


                        <li class="row c-options c-margin-b-15">


                          <div class=" col-12 full-width float-left">
                            <!-- <div class="col-lg-2 d-none d-lg-block float-left">&nbsp;</div> -->
                            <div class="col-lg-4 col-md-8 col-xs-12 col-sm-12  c-font-14 helveticaltsd pl-0 shipchoose float-left">{{ 'shipchoose' | translate }}</div>
                            <div class="col-md-6 col-xs-12 col-sm-12 c-border-top noBorderMob  float-left">

                              <div class="c-radio-list shippingContainer valRadio noMargin col-xs-12 col-sm-12 float-left pad0">
                                <p *ngIf="!this.shippingInfo" class='c-font-12 helveticaltsd'>{{ 'selectcountrytoship' | translate }}</p>

                                <ng-container *ngIf="this.shippingInfo && this.shippingInfo.length==0">
                                  {{ 'fill_shipping_details' | translate }}
                                  </ng-container>

                                <ng-container   *ngIf="this.shippingInfo">
                                  <ng-container   *ngFor="let ship of this.shippingInfo;let i=index;">
                                    <div class="c-radio col-xs-12">
                                      <input [checked]="this.selectShippingmode == ship.idshippingmode ? 'checked' : '' " (change)=" selectShipmethod(ship.idshippingmode)" value="{{ ship.idshippingmode }}" abrv="{{ ship.abbreviation }}" type="radio" name="shippingmethod" class="c-radio" id="radioS{{ ship.idshippingmode }}"  ><label class="c-font-bold c-font-14" for="radioS{{ ship.idshippingmode }}">
                                        <span class="inc"></span><span class="check"></span><span class="box"></span> {{ 'shipping_'+ship.abbreviation | translate }}</label>
                                    </div>


                                    <div [hidden]="this.selectShippingmode!= ship.idshippingmode " class=" shippingCompanyClearAll  idshippingmode_{{ ship.idshippingmode }}">
                                      	<div class="col-xs-11 col-lg-11 pull-right shipCompanies">
                                      		<div class="valRadio">
                                            <ng-container   *ngFor="let company of ship.companies;let last = last">
                                              <ng-container   *ngIf="company.abbreviation!='CNP2' || (company.abbreviation=='CNP2' && this.paymentold!='COB') ">
                                      			<div class="c-radio col-xs-12">
                                      				<input value="{{ company.idshippingcompany }}" type="radio" (change)="changeShippingCompany(company.idshippingcompany, company.abbreviation)" name="shippingcompany" class="c-radio"   id="radioC{{ company.idshippingcompany }}">
                                      					<label class="c-font-bold c-font-14" for="radioC{{ company.idshippingcompany }}">
                                      						<span class="inc"></span>
                                      						<span class="check"></span>
                                      						<span class="box"></span> {{ company.name | translate }}</label>
                                      			</div>

                                            <ng-container  *ngIf="company.abbreviation=='CNP2'">
                                            <div id="suport_pickup" *ngIf="this.pickupShipping">
                                              <div id="div_pickup">
                                                <li class="row c-options c-margin-b-15" id="codePickup"   >


                                                  <div class="col-md-12 col-xs-12  " style="padding-top: 10px;">
                                                    <div class="input-group c-square">
                                                      <input #codepostal_pickup id="codepostal_pickup" class="form-control c-square c-theme " maxlength="4" placeholder="{{ 'postalcod' | translate }}" type="text">
                                                      <span class="input-group-btn">
                                                        <button class="btn c-btn-grey-3 c-bg-black  " style="height: 38px; border-radius: 0px;" type="button" (click)="searchPickups(codepostal_pickup)">{{ 'search' | translate }}</button>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </li>

                                                <li class="row c-options c-margin-b-15 " id="codePickupPoints"   >
                                                  <div class=" col-lg-12 col-md-12"  >
                                                    <div class="c-radio-list pointsPickup  noMargin  ">

                                                       <div class="c-radio" *ngFor="let pickupS of this.pickupShippingArr;let ipick=index; ">
                                                         <input (change)="this.pickupShippingIndex=ipick" value="{{ pickupS.number }}" type="radio" name="pickup"  class="c-radio" id="radioPCK{{ pickupS.number }}"  >
 	                                                       <label class="c-font-bold c-font-14" style="line-height:normal;" for="radioPCK{{ pickupS.number }}"><span class="inc"></span><span class="check"></span><span class="box"></span> {{ pickupS.name }} - {{ pickupS.address }} </label>
                                                        </div>

                                                    </div>
                                                  </div>
                                                </li>
                                              </div>
                                            </div>
                                            </ng-container>
                                            <span *ngIf="last">{{  autoCheckShipp() }}</span>
                                            </ng-container>
                                              </ng-container>

                                      		</div>
                                      	</div>
                                      </div>

                                   </ng-container>
                                </ng-container>

                              </div>
                            </div>

                          </div>


                                                    <ng-container   *ngIf="this.shippingInfo">
                                                    <div   class="col-12 full-width float-left">
                                                      <!-- <div class="col-lg-2 d-none d-lg-block float-left">&nbsp;</div> -->
                                                      <div  class="col-lg-4 col-md-8 col-xs-12 col-sm-12 c-font-14 helveticaltsd c-border-top pl-0 paymentWrapper paymentWrapperMob float-left" style="padding-top:10px;" >{{ 'paymentchoose' | translate }}</div>
                                                      <div class="col-md-6 col-xs-12 col-sm-12 c-border-top paymentWrapper noBorderMob float-left" style="padding-top:10px;">

                                                        <div class="c-radio-list paymentContainer valRadio noMargin">

                                                          <ng-container *ngIf="this.shippingInfo.length==0">
                                                            {{ 'choosecountry' | translate }}
                                                            </ng-container>

                                                          <ng-container   *ngIf="this.shippingInfo">
                                                          <ng-container   *ngFor="let ship of this.shippingInfo;let i=index;">
                                                          <ng-container   *ngFor="let payment of ship.payments;let last = last">
                                                          <div [hidden]="this.selectShippingmode!= ship.idshippingmode " class="c-radio">
                                                        	<input value="{{ payment.idpaymentwebsite }}" (checked)="this.selectPayment=payment.idpaymentwebsite ? 'checked' : '' " (change)="selectPaymentf(payment.idpaymentwebsite,  payment.abbr)"  abrv="{{ payment.abbr }}" type="radio" name="payment"   class="c-radio" id="radioP{{ payment.idpaymentwebsite }}_{{ ship.idshippingmode }}">
                                                        	<label class="c-font-bold c-font-14" for="radioP{{ payment.idpaymentwebsite }}_{{ ship.idshippingmode }}">

                                                            <ng-container *ngIf="payment.abbr == 'MBW'">
                                                              <span class="inc"></span><span class="check"></span>
                                                              <span class="box"></span> <img src="assets/images/mbway-logo.png" alt="MB Way" class="payment-logo">
                                                            </ng-container>
                                                            <ng-container *ngIf="payment.abbr == 'MB'">
                                                              <span class="inc"></span><span class="check"></span>
                                                              <span class="box"></span> <img src="assets/images/multibanco-logo.png" alt="MB" class="payment-logo">
                                                            </ng-container>
                                                            <ng-container *ngIf="payment.abbr == 'PYPL'">
                                                              <span class="inc"></span><span class="check"></span>
                                                              <span class="box"></span> <img src="assets/images/paypal-logo.png" alt="Paypal" class="payment-logo">
                                                            </ng-container>
                                                            <ng-container *ngIf="payment.abbr == 'CC'">
                                                              <span class="inc"></span><span class="check"></span>
                                                              <span class="box"></span> <img src="assets/images/visa-logo.png" alt="Visa" class="payment-logo">
                                                            </ng-container>
                                                            <ng-container *ngIf="payment.abbr != 'MB' && payment.abbr != 'MBW' && payment.abbr != 'PYPL' && payment.abbr != 'CC'">
                                                              <span class="inc"></span><span class="check"></span>
                                                              <span class="box"></span> {{ payment.abbr | translate  }}
                                                            </ng-container>
                                                          </label>

                                                          <input type="hidden" value="{{ payment.abbr | translate  }}" id="text_radioP{{ payment.idpaymentwebsite }}_{{ ship.idshippingmode }}">
                                                        <input type="hidden" value="{{ payment.abbr }}" id="abbr_radioP{{ payment.idpaymentwebsite }}_{{ ship.idshippingmode }}">

                                                        	</div>


                                                          <ng-container   *ngIf="payment.abbr=='MBW' && this.selectShippingmode == ship.idshippingmode">
                                                            <div [style]="(this.selectPayment==payment.idpaymentwebsite) ? 'display:block' : 'display:none'"  class="c-radio">
                                                              <label   class="control-label" style="line-height: unset;">{{ 'telephone_mbw' | translate }} <i   class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
                                                              <input type="text" [required]="(this.selectPayment==payment.idpaymentwebsite && this.selectShippingmode == ship.idshippingmode) ? 'true' : 'false'"      maxlength="20" id="telephone_mbw_{{ ship.idshippingmode }}"   class="form-control c-square valRequired valNumber" />
                                                            </div>
                                                          </ng-container>


                                                          <span *ngIf="last">{{  autoCheck() }}</span>
                                                          </ng-container>
                                                          </ng-container>
                                                          </ng-container>

                                                        </div>

                                                      </div>
                                                    </div>
                                                    </ng-container>

                        </li>


                        <li class="row hidden">
                          <div class="col-lg-4 d-none d-lg-block">&nbsp;</div>
                          <div class="col col-lg-4 col-xs-6 col-sm-6 col-md-8 c-font-14 pl-0">
                            <p class="c-font-14 c-margin-b-5">{{ 'subtotal' | translate }}{{ 'withvat' | translate }} </p>
                          </div>
                          <div class="col col-md-4 col-xs-6 col-sm-6 c-font-16">
                            <p class="c-font-bold c-font-14 c-margin-b-5">

                              <span *ngIf = "this.cartTotal" class="c-shipping-total cartTotalItems">{{ this.companyInfo && this.companyInfo.typewebsite==1 ? this.cartTotal.withoutvatTxt : this.cartTotal.totalTxt }}</span>€
                            </p>
                          </div>

                        </li>

                        <li class="row hidden">
                          <!-- <div class="col col-lg-2 d-none d-lg-block">&nbsp;</div> -->
                          <div class="col col-lg-4 col-xs-6 col-sm-6 col-md-8 c-font-14 pl-0">
                            <p class="c-font-16 c-margin-b-5">{{ 'subtotal' | translate }} {{ 'withvat' | translate }} </p>
                          </div>
                          <div class="col col-md-6 col-xs-6 col-sm-6 c-font-16">
                            <p class="c-font-bold c-font-14 c-margin-b-5">

                              <span *ngIf = "this.cartTotal" class="c-shipping-total cartSubtotal">{{ this.companyInfo && this.companyInfo.typewebsite==1 ? this.cartTotal.withoutvatTxt : this.cartTotal.totalTxt }}</span> €
                            </p>
                          </div>

                        </li>



                        <li class="row voucherWrapper" [hidden]="this.cartTotal && this.cartTotal.discount<=0  " >
                          <!-- <div class="col col-lg-2 d-none d-lg-block">&nbsp;</div> -->
                          <div class="col col-lg-4 col-md-8 col-xs-6 col-sm-6 c-font-14 pl-0">
                            <p class="c-font-16 c-margin-b-5 c-theme-font">{{ 'discount' | translate }}</p>
                          </div>
                          <div class="col col-md-6 col-xs-6 col-sm-6 c-font-16">
                            <p class="c-font-bold c-font-14 c-margin-b-5 c-theme-font">
                              <span *ngIf = "this.cartTotal" class="c-shipping-total cartVoucherDiscount c-theme-font">{{   this.cartTotal.discount    }}</span>€
                            </p>
                          </div>

                        </li>

                        <li class="row discountInfo" style="display:none;">
                          <!-- <div class="col col-lg-2 d-none d-lg-block">&nbsp;</div> -->
                          <div class="col col-lg-4 col-md-8 col-xs-6 col-sm-6 c-font-14">
                            <p class="c-font-16 c-margin-b-5 c-theme-font">{{ 'discount' | translate }}</p>
                          </div>
                          <div class="col col-md-6 col-xs-6 col-sm-6 c-font-16">
                            <p class="c-font-bold c-font-14 c-margin-b-5 c-theme-font">
                              <span *ngIf = "this.cartTotal" class="c-shipping-total cartFinancialDiscount c-theme-font">{{   this.cartTotal.financialdiscount    }}</span>€
                            </p>
                          </div>

                        </li>

                        <ng-container *ngIf="this.shippingTotal &&   this.shippingTotal>0">
                        <li class="row col-12 "   >
                          <!-- <div class="col-lg-2 d-none d-lg-block">&nbsp;</div> -->
                          <div class=" col-6 col-sm-6 col-md-8 col-lg-4 pl-0">
                            <p class="c-font-12 c-margin-b-5 helveticaltsd">{{ 'delivery' | translate }} <span class='c-font-14'> {{ 'withvat' | translate }}</span> </p>
                          </div>
                          <div class="col-6 col-sm-6 col-md-6  ">
                            <p class="c-font-12 c-margin-b-5 c-font-bold">
                              <span *ngIf = "this.shippingTotal" class="c-shipping-total shippingAddStart">{{ this.shippingTotal | currency:symbol:''  }}</span>€
                            </p>
                          </div>
                        </li>
                        </ng-container>


                        <ng-container *ngIf="this.cob_value>0">
                        <li class="row col-12 "   >
                          <!-- <div class="col-lg-2 d-none d-lg-block">&nbsp;</div> -->
                          <div class=" col-6 col-sm-6 col-md-8 col-lg-4 pl-0">
                            <p class="c-font-12 c-margin-b-5 helveticaltsd">{{ 'collect' | translate }} <span class='c-font-14'> {{ 'withvat' | translate }}</span> </p>
                          </div>
                          <div class="col-6 col-sm-6 col-md-6  ">
                            <p class="c-font-12 c-margin-b-5 c-font-bold">
                              <span *ngIf = "this.cob_value" class="c-shipping-total shippingAddStart">{{ this.cob_value | currency:symbol:''  }}</span>€
                            </p>
                          </div>
                        </li>
                        </ng-container>

                        <li class="row col-12 c-margin-b-15 ">
                          <!-- <div class="col col-lg-2 d-none d-lg-block">&nbsp;</div> -->
                          <div class="col col-lg-4 col-xs-6 col-sm-6 col-md-8 c-font-16 pad0">


                            <p class="c-font-24">{{ 'total' | translate }}</p>
                            <span class="font-11">{{ 'vatincluded' | translate }} </span>

                          </div>
                          <div class="col col-md-6 col-xs-6 col-sm-6 c-font-16">
                            <p class="c-font-bold c-font-24 text-left">
                              <span *ngIf = "this.cartTotal" class="c-shipping-total cartTotalCheck text-right">{{   this.cartTotal.totalTxt   }}</span>
                              <span class="text-right">€</span>
                            </p>
                          </div>
                        </li>




                        <li class="row    d-none  d-md-block d-lg-block " style=" float: left;">

                            <div class=" col  " style="text-align:right;       margin-right: 15px;">
                              <div class="form-group col-xs-12 c-margin-b-5">
                                <div class="c-checkbox">
                                  <input id="checkGIFT_2"  #checkGIFT2 onclick="if($(this).is(':checked')){$('#checkGIFTTEXT2Div').show();}else{$('#checkGIFTTEXT2Div').hide();}" class="c-check" type="checkbox">
                                  <label for="checkGIFT_2" class="c-font-14">
                                    <span class="inc"></span>
                                    <span class="check"></span>
                                    <span class="box"></span> {{ 'INCLUDE_GIFT' | translate }}</label>
                                  </div>
                                </div>
                              </div>





                          </li>

                          <li class="clearfix"></li>



                          <li class="row    d-none  d-md-block d-lg-block "  >


                                <div id="checkGIFTTEXT2Div" class="   " style=" display:none ">
                                  <div class="form-group col-xs-12 c-margin-b-5">
                                    <label>{{ 'INCLUDE_GIFT_TEXT' | translate }}</label>
                                    <textarea maxlength="250"  #checkGIFTTEXT2    ></textarea>
                                  </div>
                                </div>


                            </li>


                          <li class="row c-margin-b-15  d-none  d-md-block d-lg-block " style=" float: left;">



                                <div class=" col  " style="text-align:right;        margin-right: 15px;">
                                  <div class="form-group col-xs-12 c-margin-b-5">
                                    <div class="c-checkbox">
                                      <input id="checkREAD_2" #checkConditions2  value="1" class="c-check" type="checkbox">
                                      <label for="checkREAD_2" class="c-font-14">
                                        <span class="inc"></span>
                                        <span class="check"></span>
                                        <span class="box"></span> {{ 'READ_AND_ACCEPT_CONDITIONS' | translate }}</label>
                                      </div>
                                    </div>
                                  </div>

                            </li>

<li class="clearfix"></li>

                        <ng-container *ngIf="this.cart && this.cart.length>0 && ((this.selectShippingmode==1 ) || this.selectShippingmode!=1) && this.selectPayment>0; else  nofinish2">
                        <button (click)="buy(checkConditions2, checkGIFT2, checkGIFTTEXT2)" class="button btn-danger wpcf7-form-control register_button mt-10 float-right hidden-xs d-xs-none d-md-block" type="button">{{ 'finishorder' | translate }}</button>
                        <div class="clearfix"></div>
                        <span style="float:right;color:red" class="hidden-xs d-xs-none d-md-block" *ngIf="this.cart && this.cart.length>0 && this.selectShippingmode==1 && this.selectShippingcompany<=0 && this.selectPayment>0">{{ 'choise_shippingcompany' | translate }}</span>
                        </ng-container >
                        <ng-template #nofinish2>
                          <div class="btn-finish-container">
                            <div class="tooltip">{{ 'missing_info' | translate }}</div>
                            <button style="background-color: #dbdbdb !important; border: #dbdbdb;" class="button wpcf7-form-control register_button mt-10 float-right hidden-xs d-xs-none d-md-block" type="button">{{ 'finishorder' | translate }}</button>
                          </div>
                        </ng-template>
                      </ng-container >

                      <ng-container *ngIf="!this.cart ||   this.cart.length==0">
                        <li class="row c-margin-b-15 c-margin-t-15">
                          <div class="col-md-8 c-font-16">
                            <p class="c-font-16">{{ 'noproductscart' | translate }}</p>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </div>


                </div>




              </div>

              <div class="d-lg-none d-md-none row">
                <div class="col-md-12">


                  <div class="   " style=" ">
                    <div class="form-group col-xs-12 c-margin-b-5">
                      <div class="c-checkbox">
                        <input id="checkGIFT_4" #checkGIFT onclick="if($(this).is(':checked')){$('#checkGIFTTEXTDiv').show();}else{$('#checkGIFTTEXTDiv').hide();}" class="c-check" type="checkbox">
                        <label for="checkGIFT_4" class="c-font-14">
                          <span class="inc"></span>
                          <span class="check"></span>
                          <span class="box"></span> {{ 'INCLUDE_GIFT' | translate }}</label>
                        </div>
                      </div>
                    </div>


                      <div id="checkGIFTTEXTDiv" class="   " style=" display:none ">
                        <div class="form-group col-xs-12 c-margin-b-5">
                          <label>{{ 'INCLUDE_GIFT_TEXT' | translate }}</label>
                          <textarea maxlength="250"  #checkGIFTTEXT    ></textarea>
                        </div>
                      </div>


                    <div class="   " style=" ">
                      <div class="form-group col-xs-12 c-margin-b-5">
                        <div class="c-checkbox">
                          <input id="checkREAD_4" #checkConditions value="1" class="c-check" type="checkbox">
                          <label for="checkREAD_4" class="c-font-14">
                            <span class="inc"></span>
                            <span class="check"></span>
                            <span class="box"></span> {{ 'READ_AND_ACCEPT_CONDITIONS' | translate }}</label>
                          </div>
                        </div>
                      </div>



                  <ng-container *ngIf="this.cart && this.cart.length>0 && ((this.selectShippingmode==1 ) || this.selectShippingmode!=1) && this.selectPayment>0; else nofinish">

                    <button class="button btn-danger wpcf7-form-control hidden-md hidden-lg hidden-xl register_button mt-10 float-right" type="button" role="button" (click)="buy(checkConditions, checkGIFT, checkGIFTTEXT)">{{ 'finishorder' | translate }}</button>
                    <div class="clearfix"></div>
                    <span style="float:right;color:red" *ngIf="this.cart && this.cart.length>0 && this.selectShippingmode==1 && this.selectShippingcompany<=0 && this.selectPayment>0">{{ 'choise_shippingcompany' | translate }}</span>
                  </ng-container>
                  <ng-template #nofinish>
                   <div class="btn-finish-container">
                      <div class="tooltip">{{ 'missing_info' | translate }}</div>
                      <button style="background-color: #dbdbdb !important; border: #dbdbdb;" class="button wpcf7-form-control hidden-md hidden-lg hidden-xl register_button mt-10 float-right" type="button" role="button">{{ 'finishorder' | translate }}</button>
                   </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>


<div id="loading" [hidden]="this.hiddenLoad" style=" opacity: 0.9;">
	<div class="mybox" id="loading-image">
		<div class="loada" id="loadingGeral"  ></div>
		<p class="noMargin">&nbsp;</p>
		<h3 class="title_h3 " id="txtLoad"></h3>
	</div>
</div>
<modal #ModalPasswordCh  id="lostPass2" >
    <modal-header>
    </modal-header>
    <modal-content>

      <div class=" row">
        <div class="  col-md-12 col-xs-12 col-sm-12">
			<span class="title_h3 c-font-24 c-font-sbold full-width">{{ 'recover_password' | translate }}.</span>
			<div class="full-width"><p class="full-width">{{ 'email_to_retrieve_password' | translate }}</p></div>
			<div class="form-group full-width">
				<input type="email" #emailrecoverPassCh class="form-control  border-grey input-lg c-square valRequired valEmail" name="email" placeholder="{{ 'write_your_email' | translate }}">
			</div>
			<div class="form-group full-width">
				<button type="button" (click)="recoverPassch()" class="col-xs-12 button register_button ml-15 float-right float-center-xs">{{ 'send' | translate }}</button>
			</div>
        </div>
      </div>

  </modal-content>
</modal>

<modal #ModalSignIn id="signIn">
  <modal-header>
  </modal-header>
  <modal-content>
    <div class="form-group">
      <form  [formGroup]="signinForm" (ngSubmit)="onSubmitSignin()">
        <div class="form-group">
          <label class="control-label" for="email">{{ 'email' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
          <input type="email" (change)="changeEmailClear($event)" [ngClass]="{ 'is-invalid': submittedSign && fSign.email.errors }" formControlName="email" id="email22" class="form-control c-square valRequired valEmail" />
          <div *ngIf="this.submittedSign && fSign.email.errors" class="invalid-feedback">
            <div *ngIf="fSign.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
            <div *ngIf="fSign.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label" for="password">{{ 'password' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
          <input type="password" id="password22" [ngClass]="{ 'is-invalid': submitted && fSign.password.errors }" formControlName="password" class="form-control c-square valRequired" />
          <div *ngIf="this.submittedSign && fSign.password.errors" class="invalid-feedback">
            <div *ngIf="fSign.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
            <div *ngIf="fSign.password.errors.minlength">{{ 'min6char' | translate }}</div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-group col-sm-4 col-xs-4 pad0">
            <div class="c-checkbox">
              <input id="checkbox122" formControlName="rememberme" name="rememberme" class="c-check" type="checkbox">
              <label for="checkbox122" class="c-font-14 helveticaltsd">
                <span class="inc"></span>
                <span class="check"></span>
                <span class="box"></span> {{ 'remember_me' | translate }}</label>
            </div>
          </div>
          <div class="noPadding col-md-8 col-xs-8 text-right float-left">
            <a (click)="closeModalSignIn();openModalRecover();" href="javascript:void(0)" class="c-btn-forgot c-font-12 helveticaltsd"> {{ 'forgot_your_password' | translate }}</a>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="row">
          <div class="col-md-12 text-center-xs">
            <button type="submit" class="col-xs-12 button wpcf7-form-control register_button ml-15 float-right float-center-xs "><span>{{ 'login' | translate }}</span></button>
            <button type="button" (click)="signInFB()" class="col-xs-12 button wpcf7-form-control register_button ml-15 float-right  float-center-xs button_facebook"><i class="zmdi zmdi-facebook"></i>{{ 'login_facebook' | translate }}</button>
          </div>
        </div>
      </form>
    </div>
  </modal-content>
</modal>