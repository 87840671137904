<div class="title-breadcrumbs" (click)="checkhideshowmenu()">
  <div class="title-breadcrumbs-inner">
    <div class="container-fluid">
      <nav class="woocommerce-breadcrumb">
        <a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate  }}</a>
        <ng-container *ngFor="let breadcrumb of this.breadcrumbs;  "  >
          <span class="separator">/</span>&nbsp;
          <a [routerLink]=" (breadcrumb.url | localize) " href="javascript:void(0)" >{{ breadcrumb.description |  titlecase }}</a>
        </ng-container>
      </nav>
    </div>
  </div>
</div>



<div class="bk-filters d-lg-none" onclick="$('#filterOptions').show();$('.filter-icon').hide();$('.filter-text').show(); $('.filters-right').hide(); $('.bk-filters').show();"></div>
<div class="container-fluid c-container-50">
  <div class="c-layout-sidebar-content c-border-2x  c-margin-t-10 c-margin-b-20">
    <div class="row">
      <div class="col-lg-12 col-xs-12 c-bg-white c-margin-b-10 mobileMenuFilter filter-sticky">
        <div class="float-left pr-30 filter-sm-12 filter-icon filters-btn" (click)="openModalFilters();"><label class="float-left chosen-filter-title"><i class="fa fa-filter"></i> <b>{{ 'filter' | translate }}</b></label></div>

<!-- <ng-container *ngIf="this.itemsList   ">

        <div class="pull-left c-relative   full-width moblie-adjustment" >
          <div class="float-left pr-30 filter-sm-12 hide-desktop filter-icon"><label class="float-left chosen-filter-title " onclick="$('#filterOptions').show();$('.filter-icon').hide();$('.filter-text').show(); $('.filters-right').hide(); $('.bk-filters').show();" (click)="setmenuhided();"><i class="fa fa-filter"></i> <b>{{ 'filter_by' | translate }}</b> </label></div>
          <div class="float-left pr-30 filter-sm-12 hide-mobile filter-text"><label class="float-left chosen-filter-title title_filter_items"><b>{{ 'order_by' | translate }} :</b></label></div>


          <div class="float-left hide-mobile filters-container" id="filterOptions">
            <span class="close_filters hide-desktop " onclick="$('#filterOptions').hide();$('.filter-icon').show();$('.filter-text').hide();$('.filters-right').show(); $('.bk-filters').hide();"(click)="this.menuhided = true;"><i class="fa fa-times"></i></span>

            <div   class="float-left pr-50  list_brands filter-sm-12">
              <label class="chosen-brand chosen-option-commom" onclick="showBrandsList()">
                <span class="title_filter_items" [class.bold]="this.filter.brand && this.filter.brand!=null">{{ 'brand' | translate }}</span>
                <span class="hide-desktop brand_selected">
                  <ng-container *ngIf="this.filter.brand==null">{{ 'allbrands' | translate  }}</ng-container>
                  <ng-container *ngIf="this.filter.brand && this.filter.brand!=null">{{ changeTextMobile(this.brands, this.filter.brand)  }}</ng-container>
                </span>
              </label>
              <ul class="div_select_brands div_filters_commom"  style="display:none;">
                <a [class]=" this.filter.brand==null ? 'selected_order brands_all' : 'brands_all'" id_input ="brand" (click)="this.filter.brand=null;this.calcnewurl('', 'b', 0)" >{{ 'allbrands' | translate }}</a>

                <ng-container *ngFor="let bran of this.brands;  "  >
                  <a [class]=" this.filter.brand==bran.idbrand ? 'selected_order title_filter_items' : 'title_filter_items'"  id_input ="brand" (click)="this.filter.brand=bran.idbrand;this.calcnewurl((bran.description), 'b', bran.idbrand);"  >{{ bran.description }}</a>
                </ng-container>


              </ul>

            </div>




            <ng-container *ngIf="this.cat  && this.cat.length>0">
                        <div class="float-left pr-50  list_brands filter-sm-12">
                          <label class="chosen-brand chosen-option-commom" onclick="showCategoryList()">
                            <span class="title_filter_items" [class.bold]="this.filter.cat &&  this.filter.cat!=null">{{ 'category' | translate }}</span>
                            <span class="hide-desktop subcategories_selected">
                              <ng-container *ngIf="this.filter.cat==null">{{ 'all_s' | translate  }}</ng-container>
                              <ng-container *ngIf="this.filter.cat &&  this.filter.cat!=null">{{ changeTextMobile(this.cat, this.filter.cat)  }}</ng-container>
                            </span>
                          </label>
                          <ul class="div_select_categories div_filters_commom"  style="display:none;">
                            <a [class]=" this.filter.cat==null ? 'selected_order title_filter_items   subcategories_all' : 'title_filter_items   subcategories_all'"  (click)="this.stopScrollF(false);this.filter.cat=null;  this.filter.subcat=null;this.filter.sub2cat=null; this.subcat=null;this.sub2cat=null;this.calcnewurl('', 'c', 0)" id_input ="cat"  >{{ 'all_s' | translate }}</a>


                            <ng-container *ngFor="let subscat of this.cat;  "  >
                              <a [class]=" this.filter.cat==subscat.id ? 'selected_order title_filter_items' : 'title_filter_items'"  id_input ="cat"  (click)="this.stopScrollF(false);this.filter.cat=subscat.id; this.filter.subcat=null;this.filter.sub2cat=null; this.subcat=null;this.sub2cat=null; this.calcnewurl((subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description), 'c', subscat.id);" >{{ subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description }}</a>
                            </ng-container>


                          </ul>

                        </div>
            </ng-container>

<ng-container *ngIf="this.subcat  && this.subcat.length>0 && this.filter.cat && this.filter.cat != null">
            <div class="float-left pr-50  list_brands filter-sm-12">
              <label class="chosen-brand chosen-option-commom" onclick="showSubcategoriesList()">
                <span class="title_filter_items" [class.bold]="this.filter.subcat &&  this.filter.subcat!=null">{{ 'subtipe' | translate }}</span>
                <span class="hide-desktop subcategories_selected">
                  <ng-container *ngIf="this.filter.subcat==null">{{ 'all_s' | translate  }}</ng-container>
                  <ng-container *ngIf="this.filter.subcat &&  this.filter.subcat!=null">{{ changeTextMobile(this.subcat, this.filter.subcat)  }}</ng-container>
                </span>
              </label>
              <ul class="div_select_subcategories div_filters_commom"  style="display:none;">
                <a [class]=" this.filter.subcat==null ? 'selected_order title_filter_items   subcategories_all' : 'title_filter_items   subcategories_all'"  (click)="this.stopScrollF(false);this.filter.subcat=null;   this.sub2cat=null;this.filter.sub2cat=null; this.calcnewurl('', 's', 0)" id_input ="subcat"  >{{ 'all_s' | translate }}</a>


                <ng-container *ngFor="let subscat of this.subcat;  "  >
                  <a [class]=" this.filter.subcat==subscat.id ? 'selected_order title_filter_items' : 'title_filter_items'"  id_input ="subcat"  (click)="this.stopScrollF(false);this.filter.subcat=subscat.id;  this.sub2cat=null;this.filter.sub2cat=null; this.calcnewurl((subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description), 's', subscat.id);" >{{ subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description }}</a>
                </ng-container>


              </ul>

            </div>
</ng-container>

            <ng-container *ngIf="this.sub2cat && this.sub2cat.length>0 &&  this.filter.subcat && this.filter.subcat != null">
            <div class="float-left pr-50  list_brands filter-sm-12">
              <label class="chosen-brand chosen-option-commom" onclick="showSub2categoriesList()">
                <span class="title_filter_items" [class.bold]="this.filter.sub2cat && this.filter.sub2cat!=null">{{ 'sub2type' | translate }}</span>
                <span class="hide-desktop sub2categories_selected">
                  <ng-container *ngIf="this.filter.sub2cat==null">{{ 'all_s' | translate  }}</ng-container>
                  <ng-container *ngIf="this.filter.sub2cat && this.filter.sub2cat!=null">{{ changeTextMobile(this.sub2cat, this.filter.sub2cat)  }}</ng-container>
                </span>
              </label>
              <ul class="div_select_sub2categories div_filters_commom"  style="display:none;">
                <a [class]=" this.filter.sub2cat==null ? 'selected_order title_filter_items   sub2categories_all' : 'title_filter_items   sub2categories_all'"  (click)="this.stopScrollF(false);this.filter.sub2cat=null;this.calcnewurl('', 'u', 0)" id_input ="sub2cat"  >{{ 'all_s' | translate }}</a>


                <ng-container *ngFor="let subscat of this.sub2cat;  "  >
                  <a [class]=" this.filter.sub2cat==subscat.id ? 'selected_order title_filter_items' : 'title_filter_items'"  id_input ="sub2cat"  (click)="this.stopScrollF(false);this.filter.sub2cat=subscat.id; this.calcnewurl((subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description), 'u', subscat.id);" >{{ subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description }}</a>
                </ng-container>


              </ul>

            </div>
            </ng-container>
 

            <div class="float-left pr-50  list_colors filter-sm-12">
              <label class="chosen-color chosen-option-commom" onclick="showColorsList()"> <span class="title_filter_items" [class.bold]="this.filter.color && this.filter.color!=null">{{ 'color' | translate }}</span>	<span class="hide-desktop color_selected" [class]="(changeTextMobile2(this.colors, this.filter.color) || this.filter.color==null) ? '' : 'multicolorbackground' " style="{{ this.filter.color!=null ? 'background-color:#'+changeTextMobile2(this.colors, this.filter.color) : ''  }}">
                <ng-container *ngIf="this.filter.color==null">{{ 'all_s' | translate  }}</ng-container>
              </span>	</label>


              <ul class="div_select_colors div_filters_commom"  style="display:none;">
                <a [class]=" this.filter.color==null ? 'selected_order title_filter_items   color_all' : 'title_filter_items   color_all'"  (click)="this.filter.color=null;calcnewurlQuery();" id_input ="codecolor"  >{{ 'all_s' | translate }}</a>


                <ng-container *ngFor="let col of this.colors;  "  >
                  <a [class]=" checkcolorSel(col.idcolor) ? 'selected_order title_filter_items button_color' : 'title_filter_items button_color'"  id_input ="codecolor" (click)="addFilterColor(col.idcolor); calcnewurlQuery();"  ><li  *ngIf="col.code" class="button_color_li"  style="background-color:#{{ col.code }}"></li><li *ngIf="!col.code" class="button_color_li multicolorbackground"   ></li> <span class=" button_color_li_span ">{{ col.langs[this.translate.currentLang] ? col.langs[this.translate.currentLang].description : col.description }}</span></a>
                </ng-container>

              </ul>
            </div>

            <div class="float-left pr-50  list_sizes filter-sm-12">
              <label class="chosen-size chosen-option-commom" onclick="showSizesList()">
                <span class="title_filter_items" [class.bold]="this.filter.size && this.filter.size!=null">{{ 'size' | translate }}</span>
                <span class="hide-desktop size_selected">
                  <ng-container *ngIf="this.filter.size==null || this.filter.size== ''">{{ 'all' | translate  }}</ng-container>
                  <ng-container *ngIf="this.filter.size && this.filter.size!=null">{{ getSizesSelected(this.sizes, this.filter.size)  }}</ng-container>
                </span>
              </label>
              <ul class="div_select_sizes div_filters_commom"  style="display:none;">
                <a [class]=" this.filter.size==null || this.filter.size=='' ? 'selected_order sizes_all' : 'sizes_all'" id_input ="size" (click)="this.filter.size=null;calcnewurlQuery();" >{{ 'all' | translate }}</a>

                <ng-container *ngFor="let bran of this.sizes;  "  >
                  <a [class]=" checksizeSel(bran.idsize)  ? 'selected_order title_filter_items' : 'title_filter_items'"  id_input ="size" (click)="addFilterSize(bran.idsize);calcnewurlQuery();"  >{{ bran.description }}</a>
                </ng-container>


              </ul>
            </div>



            <div *ngIf="this.filter.menu!=36 && this.filter.menu!=37 && this.filter.menu!=38" class="float-left pr-50  list_brands filter-sm-12">
              <label class="chosen-brand chosen-option-commom" onclick="showGenresList()"> <span class="title_filter_items">{{ 'genre' | translate }}</span>	<span class="hide-desktop genre_selected">
                <ng-container *ngIf="this.filter.genre==null">{{ 'unisex' | translate  }}</ng-container>
                <ng-container *ngIf="this.filter.genre &&  this.filter.genre!=null">{{ changeTextMobile(this.genres, this.filter.genre)  }}</ng-container>
              </span>	</label>
              <ul class="div_select_genres div_filters_commom"  style="display:none;">
                <a [class]=" this.filter.genre==null ? 'selected_order genres_all' : 'genres_all'"   id_input ="gen" (click)="this.filter.genre=null;this.calcnewurl('', 'g', 0)"  >{{ 'unisex' | translate }}</a>

                <ng-container *ngFor="let gen of this.genres;  "  >
                  <a [class]=" this.filter.genre==gen.idgenre ? 'selected_order title_filter_items' : 'title_filter_items'"  id_input ="gen"  (click)="this.filter.genre=gen.idgenre; this.calcnewurl((gen.langs[this.translate.currentLang] ? gen.langs[this.translate.currentLang].description : gen.description ), 'g', gen.id);"   >{{ gen.langs[this.translate.currentLang] ? gen.langs[this.translate.currentLang].description : gen.description }}</a>
                </ng-container>


              </ul>
            </div>


            <div style="display:none" class="float-left pr-50 filter-sm-6 filter-sm-right">
              <div class="c-checkbox  float-left title_filter_items">
                <input type="checkbox" (checked)="this.filter && this.filter.novelty==1 ? 'true' : 'false'"  (change)="filterNewItem()" id="new_filter" [class]="this.filter && this.filter.novelty==1 ? 'c-check' : 'c-check ' "  />
                <label for="new_filter" class="c-font-thin c-font-14 helveticaltsd title_filter_items"><span></span><span class="check"></span>
                  <span class="box"></span>{{ 'new' | translate }}</label>
                </div>
              </div>

              <div class="filters-bottom">
                <div class="margin-right-desk">
                  <a  (click)="this.stopScroll = false;this.itemsList=[];cleanFilters();hideMenu();this.menuhided = true;" onclick="$('.filters-right').show();"   class="btn wpcf7-form-control search_button filter-sm-button title_filter_items">
                    <i class="fa fa-times"  ></i>&nbsp;
                    <span>{{ 'clean_filters' | translate }}</span>
                  </a>
                </div>
                <div class="">
                  <a  (click)="this.stopScroll = false;this.itemsList=[];getItems(0);hideMenu();this.menuhided = true;" onclick="$('.filters-right').show();"    class="btn wpcf7-form-control search_button filter-sm-button title_filter_items">
                    <i class="fa fa-search"  ></i>&nbsp;
                    <span>{{ 'filter_show' | translate }}</span>
                  </a>
                </div>
              </div>

            </div>

          </div>-->
          <!-- <div class="filters-right">
            <div class="toolbox">
              <select name="filterprice" id="filterprice" class="form-control" (change)="changeOrderBy($event)">
                <option value="0" selected>{{ 'sortby' | translate }}</option>
                <option value="1" [selected]="this.orderItem==1">{{ 'order_ascend' | translate }}</option>
                <option value="2" [selected]="this.orderItem==2">{{ 'order_descend' | translate }}</option>
                <option value="3" [selected]="this.orderItem==3">{{ 'news' | translate }}</option>
              </select>
            </div>
          </div> -->
<!-- </ng-container>  -->
        </div>



        <div class="col-12">
          <!-- douball product start -->
          <div class="product-area pb-65 product-padding"  >
            <div class="row" id="prodHolder" infiniteScroll
      [infiniteScrollDistance]="1.5"
      [infiniteScrollThrottle]="150" (scrolled)="onScroll($event)" (scroll)="onScrollw($event)">


        <ng-container *ngIf="this.itemsList && this.translate.currentLang  ">

              <ng-container *ngFor="let item of this.itemsList;trackBy: trackByFn; let indexprimary=index    ">

                <div class="col-lg-3 col-md-4 col-xs-6 col-6 productWrapper ">
                  <div class="product-wrapper mb-35" (mouseover)="checkHover(item)" (mouseleave)="this.mouseoverElement=0"  id="prodBox{{ item.iditem }}">
                    <div class="product-img" [style]="item.thumbnailsheight>800 ? 'background-color: #f3f3f3  ;    max-height: 467px; display: table; ' : ''">
                      <a  [style]="item.thumbnailsheight>800 ? 'background-color: #f3f3f3  ;    max-height: 467px;  ' : ''" (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem)"  onclick="return false;"   class=" " [href]="item.langs[this.translate.currentLang].url">
                        <img [style]="item.thumbnailsheight >800 ? '    padding-top: 20.00%;    padding-bottom: 30%;    mix-blend-mode: multiply;    color: transparent;    width: 100%;    transform: scale(0.8);    display: inline;' : ''"  [errorImage]="item.thumbnails" [defaultImage]="this.browserwebp ? defaultImage : item.thumbnails " alt="{{ item.langs[this.translate.currentLang].description  }} - {{ 'Name_APP' | translate }}"  class=" " [lazyLoad]="item.thumbnails" />


                      </a>








                      <div class="rating-box hidden-xs hidden-sm " style="background-color: white ! important;" *ngIf="  item.itemwithattr==1   " [style]="this.mouseoverElement==item.iditem ? '' : 'display:none'">
                        <ng-container   *ngIf="    item.colors && objectKeys(item.colors).length>0 ">

                          <div class="row" style="display: block;">
                        <ng-container   *ngFor="let color of  item.colors | keyvalue ; let i=index    ">
                          <ng-container *ngIf="  i<3  ">
                            <a  (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem, color.key, size(item, color.key))"  (mouseover)="item.thumbnailscolor = color.key;changeColordefault(indexprimary, color.key);"  onclick="return false" [href]="item.langs[this.translate.currentLang].url+'/'+color.key+'/'+size(item, color.key)">
                              <div class="icon_show_image" ><img [src]="getBackgorundColor(color.value)"></div>
                            </a>
                          </ng-container>
                          <ng-container *ngIf="  item.colors &&  i==4 ">
                            <a href="">
                              <div style="display:inline-block;width:30px; height:30px; border:1px solid #ccc;margin-right:5px; background-size:100% 100%;background-color:#FFF;"><img src="assets/images/plus.png"></div>
                            </a>
                          </ng-container>



                        </ng-container>
                          </div>
                        </ng-container>

                        <div class="row" *ngIf="item.thumbnailscolor">
                        <div class="col-xs-12  " style="display: contents;">
                          <ng-container *ngFor="let siz of changeSizeList(item, item.thumbnailscolor) | keyvalue "> <div style="line-height: 2;" class="col-xs-4 col-md-2 sizetitle" (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem, item.thumbnailscolor, siz.key)"><b >{{ siz.value.number }}</b></div></ng-container>
                        </div>
                        </div>



                      </div>




                      <div (click)="openModal(item)"  [style]="this.mouseoverElement==item.iditem ? 'display: block ;' : 'display:none'" class="  product-action-2-2-block btn btn-dark  ">



                          <h6 style="color:white">{{ 'buyfast' | translate }}</h6>


                      </div>
                    </div>


                    <div class="rating-box product-img " style="margin-top: 5px;     min-height: 30px;   text-align: center;" *ngIf="  item.itemwithattr==1   " >
                      <ng-container   *ngIf="    item.colors && objectKeys(item.colors).length>0 ">
                      <ng-container   *ngFor="let color of  item.colors | keyvalue ; let i=index    ">
                        <ng-container *ngIf="  i<3  ">
                          <a  [style]="this.mouseoverElement!=item.iditem ? '' : 'display:none'"  (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem, color.key, size(item, color.key))"   href="javascript:void(0)">
                            <div class="icon_show_image" ><img [src]="getBackgorundColor(color.value)"></div>
                          </a>
                        </ng-container>
                        <ng-container *ngIf="  item.colors &&  i==4 ">
                          <a [style]="this.mouseoverElement!=item.iditem ? '' : 'display:none'" href="">
                            <div style="display:inline-block;width:30px; height:30px; border:1px solid #ccc;margin-right:5px; background-size:100% 100%;background-color:#FFF;"><img src="assets/images/plus.png"></div>
                          </a>
                        </ng-container>
                      </ng-container>
                      </ng-container>
                    </div>


                    <div [style]="this.deviceDesktop ? '' : ' ' " class="product-content  " style="padding-top:5px;">
                      <span class=" ">
                        <a (click)="urlmount(item, item.langs[this.translate.currentLang].description, item.iditem, color(item), size(item, 0))"  style="margin-right: 0px;margin-left: 0px;"   class="imgProd row  " href="javascript:void(0)">
                          <div class="col-xs-12 col-md-7">
                            <div class="row">
                        <div class="full-width text-center-smartphone" *ngIf="item.brandName "><b>{{ item.brandName  }}</b> </div>
                        <div class="full-width text-center-smartphone" *ngIf="!item.brandName "><b>{{ 'Name_APP' | translate  }}</b> </div>
                        <div class="text-center-smartphone w-100"  style="text-overflow: ellipsis;font-size: 11px;white-space: nowrap;overflow: hidden;">{{ item.langs[this.translate.currentLang].description  }}</div>
                        </div>
                        </div>

                        <div  class=" col-xs-12 col-md-5 pull-right  text-center-smartphone" style="text-align:right">
                          <div class=" ">
                            <del *ngIf="  item.withPromotion && item.withPromotion==1  ">{{ showPrice(item, 1) }}€</del>&nbsp;
                            <ins [style]="item.withPromotion && item.withPromotion==1 ? 'color:red;' : '' "><b>{{ showPrice(item, 2) }}€</b></ins>
                          </div>
                        </div>

						</a>
                      </span>
                      <div class=" product-price-2  ">
                        <div class="price-box">


                          <promo style="padding: 13px 15px;border: 1px solid #dc3545; color: #dc3545;  font-size: 17px;  background: transparent;" class="c-link" *ngIf="  item.withPromotion && item.withPromotion==1  "> -{{ showDiscount( item ) }}%</promo>
                          <new *ngIf="  item.novelty==1 && ((item.datelimitnovelty && item.datelimitnovelty>=item.datenow) || !item.datelimitnovelty )" >{{ 'new' | translate }}</new>

                        </div>
                      </div>
                    </div>


                  </div>

                </div>


              </ng-container>

              </ng-container>

<ng-container *ngIf="this.emptyItem  ">
 <span style="margin-left:15px;margin-right:15px">{{ 'noproductsfound' | translate }}</span>
</ng-container>



            </div>
          </div>
        </div>
        <div class="centerRegular">
          <div *ngIf="loadingShow" class="loada small" id="prodLoading"   ></div>
        </div>
        <div class="col-xs-12 col-md-12 pb-60"> <div class="col-xs-12 "><ng-container *ngIf="this.descText && this.descText[this.translate.currentLang] && this.descText[this.translate.currentLang].descriptioncomplete"> <span [innerHTML]="this.descText[this.translate.currentLang].descriptioncomplete" ></span></ng-container> </div> </div>


      </div>
    </div>
  </div>


  <modal #ModalFilters id="filters">
    <modal-header>
      <h6 class="c-font-12 c-font-sbold name_item m-0">{{ 'filter_by' | translate }}</h6>
    </modal-header>
    <modal-content style="width: 100%;">
      <mat-accordion class="example-headers-align" multi>
        <ng-container *ngIf="this.brands && this.brands.length > 0">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h6><b>{{ 'brand' | translate }}</b></h6>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="col-md-12 mt-3">
              <div class="c-checkbox">
                <input id="brand_all" class="c-check" type="checkbox" [checked]="this.filter.brand==null" (click)="this.filter.brand=null; this.calcnewurl('', 'b', 0)">
                <label for="brand_all" class="label-checkbox-filter text-uppercase">
                  <span class="inc"></span>
                  <span class="check"></span>
                  <span class="box"></span>{{ 'allbrands' | translate }}
                </label>
              </div>
              <ng-container *ngFor="let b of this.brands">
                <div class="c-checkbox">
                  <input [id]="'brand_'+b.id" class="c-check" type="checkbox" [checked]="checkFilterSelMult(b.idbrand, 'brand')" (click)="addFilterMulti(b.idbrand, 'brand');">
                  <label [for]="'brand_'+b.id" class="label-checkbox-filter">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>{{ b.description }}
                  </label>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="(this.cat && this.cat.length>0) || (this.subcat && this.subcat.length>0) || (this.sub2cat && this.sub2cat.length>0)">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <ng-container *ngIf="this.cat && this.cat.length>0">
                  <h6><b>{{ 'category' | translate }}</b></h6>
                </ng-container>
                <ng-container *ngIf="this.subcat && this.subcat.length>0">
                  <h6><b>{{ 'type' | translate }}</b></h6>
                </ng-container>
                <ng-container *ngIf="this.sub2cat && this.sub2cat.length>0">
                  <h6><b>{{ 'subtipe' | translate }}</b></h6>
                </ng-container>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Categories -->
            <ng-container *ngIf="this.cat && this.cat.length>0">
              <div class="col-md-12 mt-3">
                <div class="c-checkbox">
                  <input id="cat_all" class="c-check" type="checkbox" [checked]="this.filter.cat==null" (click)="this.filter.cat=null">
                  <label for="cat_all" class="label-checkbox-filter">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>{{ 'all_category' | translate }}
                  </label>
                </div>
                <ng-container *ngFor="let cat of this.cat">
                  <div class="c-checkbox">
                    <input [id]="'cat_'+cat.id" class="c-check" type="checkbox" [checked]="this.filter.cat==cat.id" (click)="this.filter.cat=cat.id;this.filter.subcat=null;this.filter.sub2cat=null; this.subcat=null;this.sub2cat=null;">
                    <label [for]="'cat_'+cat.id" class="label-checkbox-filter">
                      <span class="inc"></span>
                      <span class="check"></span>
                      <span class="box"></span>{{ cat.langs[this.translate.currentLang] ? cat.langs[this.translate.currentLang].description : cat.description }}
                    </label>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <!-- Sub Categories -->
            <ng-container *ngIf="this.subcat && this.subcat.length>0">
              <div class="col-md-12 mt-3">
                <div class="c-checkbox">
                  <input id="cat_all" class="c-check" type="checkbox" [checked]="this.filter.subcat==null" (click)="this.filter.subcat=null">
                  <label for="cat_all" class="label-checkbox-filter">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>{{ 'all' | translate }}
                  </label>
                </div>
                <ng-container *ngFor="let subscat of this.subcat">
                  <div class="c-checkbox">
                    <input [id]="'cat_'+subscat.id" class="c-check" type="checkbox" [checked]="this.filter.subcat==subscat.id" (click)="this.filter.subcat=subscat.id;this.sub2cat=null;this.filter.sub2cat=null;">
                    <label [for]="'cat_'+subscat.id" class="label-checkbox-filter">
                      <span class="inc"></span>
                      <span class="check"></span>
                      <span class="box"></span>{{ subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description }}
                    </label>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <!-- Sub Sub Categories -->
            <ng-container *ngIf="this.sub2cat && this.sub2cat.length > 0">
              <div class="col-md-12 mt-3">
                <div class="c-checkbox">
                  <input id="cat_all" class="c-check" type="checkbox" [checked]="this.filter.sub2cat==null" (click)="this.filter.sub2cat=null">
                  <label for="cat_all" class="label-checkbox-filter">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>{{ 'all' | translate }}
                  </label>
                </div>
                <ng-container *ngFor="let subscat of this.sub2cat">
                  <div class="c-checkbox">
                    <input [id]="'cat_'+subscat.id" class="c-check" type="checkbox" [checked]="this.filter.sub2cat==subscat.id" (click)="this.filter.sub2cat=subscat.id;">
                    <label [for]="'cat_'+subscat.id" class="label-checkbox-filter">
                      <span class="inc"></span>
                      <span class="check"></span>
                      <span class="box"></span>{{ subscat.langs[this.translate.currentLang] ? subscat.langs[this.translate.currentLang].description : subscat.description }}
                    </label>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="this.colors && this.colors.length > 0">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h6><b>{{ 'color' | translate }}</b></h6>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="col-md-12 mt-3">
              <div class="c-checkbox">
                <input id="color_all" class="c-check" type="checkbox" [checked]="this.filter.color==null" (click)="this.filter.color=null;">
                <label for="color_all" class="label-checkbox-filter">
                  <span class="inc"></span>
                  <span class="check"></span>
                  <span class="box"></span>{{ 'allcolors' | translate }}
                </label>
              </div>
              <ng-container *ngFor="let color of this.colors;">
                <div class="c-checkbox">
                  <input [id]="'color_'+color.id" class="c-check" type="checkbox" [checked]="colorSected(color.idcolor)" (click)="addFilterColor(color.idcolor);">
                  <label [for]="'color_'+color.id" class="label-checkbox-filter">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span> {{color.langs[this.translate.currentLang] ? color.langs[this.translate.currentLang].description : color.description}}  <div *ngIf="color.code" class="button_color_li"  style="background-color:#{{ color.code }}"></div> <div *ngIf="!color.code" class="button_color_li multicolorbackground"></div>
                  </label>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="this.sizes && this.sizes.length > 0">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h6><b>{{ 'size' | translate }}</b></h6>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="col-md-12 mt-3">
              <div class="c-checkbox">
                <input id="size_all" class="c-check" type="checkbox" [checked]="this.filter.size==null" (click)="this.filter.size=null;">
                <label for="size_all" class="label-checkbox-filter">
                  <span class="inc"></span>
                  <span class="check"></span>
                  <span class="box"></span>{{ 'allsizes' | translate }}
                </label>
              </div>
              <ng-container *ngFor="let size of this.sizes;">
                <div class="c-checkbox">
                  <input [id]="'size_'+size.id" class="c-check" type="checkbox" [checked]="checksizeSel(size.idsize)" (click)="addFilterSize(size.idsize);">
                  <label [for]="'size_'+size.id" class="label-checkbox-filter">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span><ng-container *ngIf="size.idsize == '1'; else otherSize">{{ 'TU' | translate }}</ng-container><ng-template #otherSize>{{ size.description }}</ng-template>
                  </label>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="this.genres && this.genres.length > 0 && this.filter.menu!=36 && this.filter.menu!=37 && this.filter.menu!=38">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h6><b>{{ 'genre' | translate }}</b></h6>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="col-md-12 mt-3">
              <div class="c-checkbox">
                <input id="genre_all" class="c-check" type="checkbox" [checked]="this.filter.genre==null" (click)="this.filter.genre=null;">
                <label for="genre_all" class="label-checkbox-filter">
                  <span class="inc"></span>
                  <span class="check"></span>
                  <span class="box"></span>{{ 'allgenres' | translate }}
                </label>
              </div>
              <ng-container *ngFor="let gen of this.genres;">
                <div class="c-checkbox">
                  <input [id]="'genre_'+gen.id" class="c-check" type="checkbox" [checked]="this.filter.genre==gen.idgenre" (click)="this.filter.genre=gen.idgenre">
                  <label [for]="'genre_'+gen.id" class="label-checkbox-filter">
                    <span class="inc"></span>
                    <span class="check"></span>
                    <span class="box"></span>{{ gen.langs[this.translate.currentLang] ? gen.langs[this.translate.currentLang].description : gen.description }}
                  </label>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
      <div class="col-md-12 mt-3">
        <div class="toolbox">
          <select name="filterprice" id="filterprice" class="form-control" (change)="changeOrderBy($event)">
            <option value="0" selected>{{ 'sortby' | translate }}</option>
            <option value="1" [selected]="this.orderItem==1">{{ 'order_ascend' | translate }}</option>
            <option value="2" [selected]="this.orderItem==2">{{ 'order_descend' | translate }}</option>
            <option value="3" [selected]="this.orderItem==3">{{ 'news' | translate }}</option>
            <option value="4" [selected]="this.orderItem==4">{{ 'newest_products' | translate }}</option>
            <option value="5" [selected]="this.orderItem==5">{{ 'older_products' | translate }}</option>
          </select>
        </div>
      </div>
    </modal-content>
    <modal-footer>
      <div class="btn wpcf7-form-control search_button filter-sm-button title_filter_items" (click)="this.stopScroll = false;this.itemsList=[];cleanFilters();getItems(0);closeModalFilters();">{{ 'clean_filters' | translate }}</div>
      <div class="btn wpcf7-form-control search_button filter-sm-button title_filter_items btn-dark ml-2" (click)="this.stopScroll = false;this.itemsList=[];getItems(0);closeModalFilters();">{{ 'search' | translate }}</div>
    </modal-footer>
  </modal>

  <modal #ModalDetail  id="buyFast" >
    <modal-header>

      <h6 class="c-font-12 c-font-sbold name_item m-0" *ngIf="this.selectItem"> {{ this.selectItem.langs[this.translate.currentLang].description }} </h6>

    </modal-header>
    <modal-content>


      <div class=" row"  >


        <div class="imagesProd col-md-6 col-xs-6 col-sm-6">
          <div class="col-lg-12" style="width:410px !important;max-height:380px;"><div>
            <ng-container   *ngIf="this.selectItem && this.selectItem.itemwithattr==1 ">
                <div [class]="'product-details-img-content galleryDisplay gallery'"  cont="{{ i }}" >
                  <div class="product-details-tab" >
                    <div class="slider-area">
                          <owl-carousel-o class="slider-active product-details-large product-slider-one  "     [options]="carouselOptionsDetail"    >
                             <ng-container   *ngFor="let img of  this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].images | keyvalue ; let i1=index    ">
                              <ng-template carouselSlide       [width]="380" >
                              <div class="item"><img  style="max-height:380px; object-fit: cover;"  [defaultImage]="this.browserwebp ? defaultImage : this.apiService.imagesDir+img.value.photo" [alt]="this.selectItem.langs[this.translate.currentLang].description" [lazyLoad]="(this.browserwebp && img.value.webp) ? this.apiService.imagesDir+img.value.webp : this.apiService.imagesDir+img.value.photo"  class="col-xs-12 noPadding  "/></div>
                              </ng-template>
                            </ng-container>
                           </owl-carousel-o>
                      </div>
                  </div>
                </div>
            </ng-container>
            <ng-container   *ngIf="this.selectItem && this.selectItem.itemwithattr==0 ">
                <div class="'product-details-img-content galleryDisplay gallery "    >
                  <div class="product-details-tab"  >
                    <div class="slider-area">
                          <owl-carousel-o class="slider-active product-details-large product-slider-one  "     [options]="carouselOptionsDetail"    >
                             <ng-container   *ngFor="let img of  this.selectItem.images ; let i1=index    ">
                               <ng-template carouselSlide       [width]="380"    >
                              <div class="item"><img  style="max-height:380px"   [defaultImage]="this.browserwebp ? defaultImage : this.apiService.imagesDir+img.photo" [alt]="this.selectItem.langs[this.translate.currentLang].description" [lazyLoad]="(this.browserwebp && img.webp) ? this.apiService.imagesDir+img.webp : this.apiService.imagesDir+img.photo"  class="col-xs-12 noPadding max-height-380 "/></div>
                              </ng-template>
                            </ng-container>
                           </owl-carousel-o>
                      </div>
                  </div>
                </div>
            </ng-container>
          </div>
        </div>
      </div>



      <div class="  col-md-6 col-xs-6 col-sm-6">
        <div class="infoProd mb-4" *ngIf="this.selectItem">
          <p class="noPadding col-xs-12 m-0 helveticaltsd c-font-13" style="font-size: 20px; font-weight: 600">{{ this.selectItem.description }}</p>
          <input type="hidden" name="iditem" value="{{ this.selectItem.iditem }}"/><input type="hidden" name="code" value="{{ this.selectItem.code }}"/>
          <p class="noPadding col-xs-12 m-0 helveticaltsd c-font-13 mt-2">{{ 'refa' | translate }} : {{ this.selectItem.code }}</p>
          <p *ngIf="this.selectItem.withPromotion == 1 && this.selectItem.endPromo"><b>{{'offer_ends_in' | translate}}: </b> <span>{{this.selectItem.endPromo}}</span></p>
        </div>

          <div class="b-margin-20">
            <ng-container   *ngIf="this.selectItem && this.selectItem.itemwithattr==1 ">
            <div class="col-xs-12 c-color-wrapper mt-2">
              <span class="c-input-title helveticaltsdr c-font-11 ccblack full-width float-left">{{ 'colors' | translate }}:</span>
              <span class="c-custom-labels" data-toggle="buttons">
              <ng-container   *ngFor="let color of  this.selectItem.colors | keyvalue ; let i=index ; first as isFirst ">
                  <label  [class]=" color.key==this.selectItem.colorActive ?  'btn c-btn-square active' : 'btn c-btn-square '  "><div class="labelColor" style="  background-size: 100% 100%;"><img alt="{{ this.selectItem.langs[this.translate.currentLang].description   }} - {{ showcolorInfo(color,2) }} - {{ 'Name_APP' | translate }}" src="{{
                    (this.browserwebp && color.value[objectKeys(color.value)[0]].images[0].webp) ? this.apiService.imagesDir+color.value[objectKeys(color.value)[0]].images[0].webp : this.apiService.imagesDir+color.value[objectKeys(color.value)[0]].images[0].photo }}"></div><input (change)="changeColor(color.key)" class="inputTypeHidden" value="{{ color.key }}" name="color" data-colorname="{{ showcolorInfo(color,2) }}" id="inputColor_{{ color.key }}"   type="radio"  />
                  </label>
              </ng-container>
            </span>
            </div>
          </ng-container>
            <ng-container   *ngIf="this.selectItem && this.selectItem.itemwithattr==1 ">
              <div class="col-xs-12 c-color-wrapper">
                <span class="c-input-title helveticaltsdr c-font-11 ccblack full-width float-left">{{ 'size' | translate }}:</span>
                <span class="c-custom-labels2" data-toggle="buttons">
                    <ng-container   *ngFor="let color of  this.selectItem.colors | keyvalue ; let i=index ; first as isFirst ">
                      <ng-container   *ngFor="let size of  color.value    | keyvalue ; let i1=index    ">
                        <label [style]=" color.key==this.selectItem.colorActive ?  '' : 'display:none'  "   [class]=" size.key==this.selectItem.sizeActive ?  'btn c-btn-square llabelSize backgroundgray active sizeBtn' : 'btn c-btn-square llabelSize sizeBtn'  "><div class="labelSize" style="  background-size: 100% 100%;">{{ size.key==1 ? ('uniquesize' | translate)  : size.value.number }}</div><input (change)="changeSize(size.key)" class="inputTypeHidden" value="{{ size.key }}" name="size" data-sizename="{{ size.value.number }}" id="inputSize_{{ color.key }}_{{ size.key }}"   type="radio"  /></label>
                      </ng-container>
                    </ng-container>
                </span>
              </div>
          </ng-container>
        </div>
        <div class="priceProd col-md-12 noPadding">
          <div class="clearfix"></div>
          <p class="product-price">
            <del *ngIf="  this.selectItem &&  this.selectItem.withPromotion && this.selectItem.withPromotion==1  " id="pricestart" class="old">{{ loadPriceFastBuy(1) }}€</del>
            <span [style]="this.selectItem &&  this.selectItem.withPromotion && this.selectItem.withPromotion==1  ? 'color:red' : ''" id="pricefinal"><b>{{ loadPriceFastBuy(2) }}€</b></span>
          </p>
          <button type="button" *ngIf="this.selectItem && this.selectItem.state==1" (click)="buyProduct();closeModal()" class="btn-style cr-btn cartBtn full-width c-square text-center font-12 float-left fastBuyAddCart btn-danger">
            {{ 'add_to_cart' | translate }}
          </button>
          <button type="button" *ngIf="this.selectItem && this.selectItem.state==0" class="btn-style cr-btn cartBtn full-width c-square text-center font-12 float-left fastBuyAddCart btn-danger">
            {{ 'nostock' | translate }}
          </button>

          <div class="noPadding col-md-12 mt-2">
            <div class="col-sm-6 col-md-6 float-left pl-0 mt-3">
              <button (click)="closeModal()" type="button" data-dismiss="modal" style="  border-radius: 0px;background-color:#f0f0f0; font-size: 12px;" class="btn btn-default btn-outline btn-cart button-aylen cartBtn full-width float-left ">{{ 'keepbuying' | translate }}</button>
            </div>
            <div class="col-sm-6 col-md-6 float-left pr-0 mt-3">
              <button type="button" style="border-radius: 0px;background-color:#ccc; font-size: 12px;"   (click)="checkoutGo()" [routerLink]="(['/checkout'] | localize)" class="btn btn-outline btn-default btn-cart button-aylen cartBtn full-width float-left ">{{ 'finishorder' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </modal-content>
</modal>
